<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<wp_login v-if="page" v-model="site" :workspace="workspace" :redirect="wireframe_preview" v-on:update:submitted="update_submitted" />

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<build_nav :site="site" :is_back="true" :preview="staging" :wpurl="preview" />

				<div v-if="page" class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="bg-white overflow-hidden flex-grow-1 position-relative" style="padding-bottom: 50px;">
						<div class="position-absolute top-50 start-50 translate-middle">
							<span class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</span>
						</div>

						<iframe 
						v-on:load="load_iframe"
						name="iframe-preview" 
						class="w-100 h-100 border-bottom position-relative" 
						:class="[(!iframe_loaded || !wp_submitted ? 'opacity-0' : '')]"
						></iframe>
					</div>
				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import build_nav from '@/components/build_nav'
import wp_login from '@/components/wp_login'
import page_service from '@/services/page_service'

export default {
	name: 'site.design.page',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		build_nav,
		wp_login
	},

	data() {
		return {
			loading: false,
			wp_submitted: false,
			iframe_loaded: false,
			site: null,
			page: null,
			version: 0,
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		staging()
		{
			var url = 'https://'

			if ( this.workspace && this.workspace.custom_domain ) {
				url += this.workspace.custom_domain + '/storage/'
			} else if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
		
		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		wireframe_preview()
		{
			var url = this.preview

			if ( this.page ) {
				url += '/wp-admin/post.php?post=' + this.page.ID + '&verion=' + this.version + '&action=edit'
			}

			return url
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_page()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_page()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress_page( this.$route.params.workspace_id, this.$route.params.site_id, this.$route.params.page_id ).then(( response ) => {

				this.page = response.data

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		generate_wireframe()
		{
			this.loading = true 

			this.reset_error()

			page_service.generate_wireframe( this.$route.params.workspace_id, this.$route.params.site_id, this.page ).then(() => {

				this.version++

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		update_submitted( e )
		{
			this.wp_submitted = e
		},

		load_iframe()
		{
			this.iframe_loaded = true;
		},
	}
}
</script>